.overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.7;
  transition: all 0.5s;
  z-index: 200;
}

.hidden {
  height: 0;
  opacity: 0;
  transition: all 0.5s;
}
