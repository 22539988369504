@import 'accets/scss/variables.scss';

#root {
  font-family: $mainFontFamily;
}

.App {
  overflow: hidden;
  position: relative;
}

.h-100vh {
  min-height: 100vh;
}

.br-10 {
  border-radius: 10px;
}

.bg {
  min-height: 100vh;
}

.bg::after {
  border: 1px solid red;
  content: '';
  background: no-repeat url('./accets/img/FR-bg.svg');
  background-size: contain;
  background-position-y: 40%;
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  display: none;

  @media (min-width: 992px) {
    display: flex;
  }
}

::-webkit-scrollbar {
  height: 8px;
  position: absolute;
  bottom: -20px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #ffbb4f;
  border-radius: 9em;
}

::-webkit-scrollbar-track {
  background: #f3f3f3;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 14px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ffbb4f;
}

#modal-root {
  position: relative;
  z-index: 999;
}

.modal-container {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  height: fit-content;
  min-height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 300;
}
