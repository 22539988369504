@import '../../accets/scss/variables';

.logo-img {
  width: 50px;
  height: 50px;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    width: 100px;
    height: 100px;
    margin-bottom: 0;
  }

  @media (min-width: 992px) {
    width: 143px;
    height: 136px;
  }
}

.small-logo-img {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}

.logo-title {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: bold;
  line-height: 37px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    margin-bottom: 60px;
    line-height: 91px;
  }
}

.small-logo-title {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.first-word {
  color: $logoLightColor;
}

.second-word {
  color: $logoDarkColor;
}
