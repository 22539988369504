@import 'src/accets/scss/variables';

.payment {
  display: none;

  @media (min-width: 768px) {
    background-color: $lightColor;
    border: 1px solid $btnChangeLangColor;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 3fr 2fr;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 7px 0;
    line-height: 16px;
    box-shadow: none;
  }

  @media (min-width: 992px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: grid;
  }
}

.payment-mobile {
  display: flex;
  justify-content: start;
  box-shadow: $paymentBoxShadow;
  background-color: $lightColor;
  border-radius: 10px;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 20px;

  &:last-of-type {
    margin-bottom: 40px;
  }

  @media (min-width: 768px) {
    display: none;
  }

  @media (min-width: 992px) {
    display: flex;
  }

  @media (min-width: 1200px) {
    display: none;
  }
}

.payment-sum-container {
  height: 42px;
  margin-bottom: 25px;
}

.payment-data,
.payment-sum {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: bold;
  font-size: $paymentSumFontSize;
  line-height: 20px;
  color: $exampleFileBtnColor;

  @media (min-width: 768px) {
    font-family: $additionalFontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: $paymentDataFontSize;
    align-self: center;
    color: $mainDarkFontColor;
  }

  @media (min-width: 992px) {
    font-family: $mainFontFamily;
    font-style: normal;
    font-weight: bold;
    font-size: $paymentSumFontSize;
    line-height: 20px;
    color: $exampleFileBtnColor;
  }

  @media (min-width: 1200px) {
    font-family: $additionalFontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: $paymentDataFontSize;
    align-self: center;
    color: $mainDarkFontColor;
  }
}

.data-mobile-container {
  right: 20px;
  top: 10px;
}

.payment-data {
  &:first-of-type {
    align-self: flex-start;

    @media (min-width: 768px) {
      align-self: center;
    }

    @media (min-width: 992px) {
      align-self: flex-start;
    }

    @media (min-width: 1200px) {
      align-self: center;
    }
  }
}

.payment-mobile-data {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: $commentNameFontSize;
  line-height: 20px;
  color: #979797;

  &:first-of-type {
    margin-right: 10px;
  }
}

.payment-sum {
  margin-right: 0;
  font-size: $paymentSumFontSize;
  align-self: center;
  color: $mainLightFontColor;
  font-weight: bold;
}

.payment-status {
  border-radius: 10px;
  padding: 12px 0;
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: $paymentStatusFontSize;
  color: $lightColor;

  &::first-letter {
    text-transform: uppercase;
  }
}

.status-expected {
  background-color: $statusExpectedBgColor;
}

.status-expired {
  background-color: $statusExpiredBgColor;
}

.status-paid {
  background-color: $statusPaidBgColor;
}

.download-bill-btn.btn.btn-link {
  border-radius: 10px;
  background-color: $mainDarkFontColor;
  border: none;
  padding: 11px 20px;
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: $downloadBillBtnFontSize;
  line-height: 20px;
  color: $lightColor;
  right: 0;

  &:active,
  &:focus {
    box-shadow: 0 0 0 0.2rem $mainLightFontColor;
  }

  &::first-letter {
    text-transform: uppercase;
  }

  .download-icon {
    width: 20px;
    height: 20px;
  }
}
