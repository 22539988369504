@import '../../accets/scss/variables';

.edits-page-title {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: bold;
  font-size: $editsPageTitleFontSize;
  line-height: 20px;
  color: $mainDarkFontColor;
  margin-bottom: 20px;
}

.edit-form-btn.btn.btn-primary {
  margin-bottom: 0;
}

.edits-section {
  max-width: 1000px;
  margin: 0 auto 40px auto;

  @media (min-width: 992px) {
    height: 52vh;
    padding-right: 14px;
    overflow: auto;
  }
}

.edits-form-container-mobile {
  margin: 0 -10px 20px -10px;
}

.edits-form-container {
  border-radius: 10px;
  background-color: $editFormBgColor;
  padding: 15px;
  margin: 0 0 20px -10px;

  @media (min-width: 992px) {
    padding: 20px;
    margin: 0 -14px 20px 0;
  }
}

.edits-form-container-mobile {
  border-radius: 10px;
  background-color: $editFormBgColor;
  padding: 15px;
  position: fixed;
  bottom: 100px;
  left: 10px;
  right: 10px;
}
