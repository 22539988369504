@import '../../accets/scss/variables';

.filter-btn-container {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 992px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 1350px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.filter-btn-first-container {
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: flex-end;
  }

  @media (min-width: 992px) {
    justify-content: center;
  }
}

.filter-btn-second-container {
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }

  @media (min-width: 992px) {
    justify-content: center;
  }
}

.filter-btn-title {
  &::first-letter {
    text-transform: uppercase;
  }
}

.new-btn.btn.btn-primary,
.under-review-btn.btn.btn-primary,
.discuss-btn.btn.btn-primary,
.accepted-btn.btn.btn-primary,
.done-btn.btn.btn-primary,
.reject-btn.btn.btn-primary,
.create-edit-btn.btn.btn-primary,
.edit-form-btn.btn.btn-primary {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  padding: 11px 5px;
  margin-right: 5px;
  line-height: 15px;
  color: $mainDarkFontColor;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid $newEditsBtnBorderColor;
  white-space: nowrap;
  margin-bottom: 20px;

  &:nth-child(3) {
    margin-right: 0;

    @media (min-width: 1350px) {
      margin-right: 20px;
    }
  }

  .filter-btn-count {
    top: -10px;
    right: -10px;
    width: 30px;
    height: 20px;
    font-size: 12px;
    border-radius: 6px;
    background-color: $newEditsBtnBgColor;
    border: 1px solid $newEditsBtnBorderColor;
  }

  &.active-btn,
  &:focus,
  &:active {
    box-shadow: none !important;
    border: 1px solid $newEditsBtnBorderColor;
    background-color: $newEditsBtnBgColor !important;
  }

  @media (min-width: 375px) {
    font-size: $showProjectMobileBtnFontSize;
    padding: 11px 10px;
    margin-right: 11px;
  }

  @media (min-width: 992px) {
    font-size: $showProjectBtnFontSize;
    line-height: 20px;
    margin-right: 20px;
  }
}

.under-review-btn.btn.btn-primary {
  color: $underReviewBtnBorderColor;
  border-color: $underReviewBtnBorderColor;

  .filter-btn-count {
    color: $mainDarkFontColor;
    background-color: $underReviewBtnBgColor;
    border: 1px solid $underReviewBtnBorderColor;
  }

  &.active-btn,
  &:focus,
  &:active {
    background-color: $underReviewBtnBgColor !important;
    color: $mainDarkFontColor;
    border: 1px solid $underReviewBtnBorderColor;
  }
}

.discuss-btn.btn.btn.btn-primary {
  color: $discussEditsBtnBorderColor;
  border-color: $discussEditsBtnBorderColor;

  .filter-btn-count {
    color: $mainDarkFontColor;
    background-color: $discussEditsBtnBgColor;
    border: 1px solid $discussEditsBtnBorderColor;
  }

  &.active-btn,
  &:focus,
  &:active {
    background-color: $discussEditsBtnBgColor !important;
    border: 1px solid $discussEditsBtnBorderColor;
    color: $mainDarkFontColor;
  }
}

.accepted-btn.btn.btn-primary {
  color: $acceptedBtnBorderColor;
  border-color: $acceptedBtnBorderColor;

  .filter-btn-count {
    color: $mainDarkFontColor;
    background-color: $acceptedBtnBgColor;
    border: 1px solid $acceptedBtnBorderColor;
  }

  &.active-btn,
  &:focus,
  &:active {
    background-color: $acceptedBtnBgColor !important;
    border: 1px solid $acceptedBtnBorderColor;
    color: $mainDarkFontColor;
  }

  @media (min-width: 768px) {
    margin-left: 10px;
  }

  @media (min-width: 1350px) {
    margin-left: 0;
  }
}

.done-btn.btn.btn-primary {
  color: $doneEditsBtnBorderColor;
  border-color: $doneEditsBtnBorderColor;

  .filter-btn-count {
    color: $mainDarkFontColor;
    background-color: $doneEditsBtnBgColor;
    border: 1px solid $doneEditsBtnBorderColor;
  }

  &.active-btn,
  &:focus,
  &:active {
    background-color: $doneEditsBtnBgColor !important;
    border: 1px solid $doneEditsBtnBorderColor;
    color: $mainDarkFontColor;
  }
}

.reject-btn.btn.btn.btn-primary {
  color: $rejectEditsBtnBorderColor;
  border-color: $rejectEditsBtnBorderColor;

  .filter-btn-count {
    color: $mainDarkFontColor;
    background-color: $rejectEditsBtnBgColor;
    border: 1px solid $rejectEditsBtnBorderColor;
  }

  &.active-btn,
  &:focus,
  &:active {
    background-color: $rejectEditsBtnBgColor !important;
    color: $mainDarkFontColor;
  }
}

.create-edit-btn.btn.btn-primary,
.edit-form-btn.btn.btn-primary {
  margin-right: 0;
  background-color: $mainDarkFontColor;
  color: $lightColor;
  padding: 13px;

  &::first-letter {
    text-transform: uppercase;
  }

  &:focus,
  &:active {
    background-color: $mainDarkFontColor !important;
    color: $lightColor;
  }

  @media (min-width: 992px) {
    padding: 11px 10px;
  }
}
