@import '../../accets/scss/variables';

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 80px;
  padding: 0 18px;
  background-color: $lightColor;
}

.footer-btn-icon-active {
  height: 60px;
  width: 54px;
}

.footer-btn {
  width: 54px;
  display: flex;
  justify-content: center;
}

.footer-btn-icon {
  width: 20px;
  height: 20px;
}

.active-footer-btn {
  align-self: flex-start;
  margin-top: -27px;
}
