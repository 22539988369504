@import '../../accets/scss/variables';

.btn-edit.btn.btn-primary {
  position: absolute;
  right: 0;
  top: 35px;
  background-color: transparent;
  border: none;

  &:active,
  &:focus,
  &:hover {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.input-label {
  &::first-letter {
    text-transform: uppercase;
  }
}

.logout-form-error {
  color: $rejectEditsBtnBorderColor;
  font-family: $mainFontFamily;
  font-size: 10px;
  position: absolute;
  bottom: -2px;
  left: 11px;

  &::first-letter {
    text-transform: uppercase;
  }
}
