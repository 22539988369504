.body {
  composes: body from '../UserBar.module.scss';
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 32px;
  border-bottom: none;
}

.userInfo {
  composes: userInfo from '../UserBar.module.scss';
}

.routeBtn {
  composes: routeBtn from '../UserBar.module.scss';
}

.userName {
  composes: userName from '../UserBar.module.scss';
  margin: 0 0 0 20px;
}

.userJob {
  composes: userJob from '../UserBar.module.scss';
  margin: 0 0 0 10px;
}

.statsList {
  composes: statsList from '../UserBar.module.scss';
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-shrink: 0;
  margin: 0;
  align-items: center;
  list-style: none;
  padding: 0;
}

.stat {
  composes: stat from '../UserBar.module.scss';
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }

  &::after {
    content: '';
    width: 19px;
    height: 19px;
    margin-left: 10px;
    margin-right: 20px;
    display: inline-block;
    transform: translateY(20%);
    background: center / cover no-repeat url('../../../accets/icons/stat-plus-btn.png');
  }
}

.statValueTxt {
  composes: statValueTxt from '../UserBar.module.scss';
}

.balance {
  composes: balance from '../UserBar.module.scss';
}

.kpi {
  composes: kpi from '../UserBar.module.scss';
}

.level {
  composes: level from '../UserBar.module.scss';
}
