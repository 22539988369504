@import '../../accets/scss/variables';

.greeting-user-cloud-img {
  max-width: 717px;
  width: 100%;
  height: 296px;
}

.greeting-content {
  padding: 0 20px;
  height: 74%;
  justify-content: center;
  align-items: center;
}

.greeting-user-title-container {
  margin-bottom: 4px;
  flex-direction: column;

  @media (min-width: 360px) {
    margin-bottom: 14px;
  }

  @media (min-width: 411px) {
    flex-direction: row;
  }

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }

  @media (min-width: 992px) and (min-height: 769px) {
    margin-bottom: 40px;
  }
}

.greeting-content-waive-hand {
  margin-right: 10px;
  width: 30px;
  height: 30px;

  @media (min-width: 720px) {
    width: 43px;
    height: 42px;
  }
}

.greeting-user-title {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: $greetingUserTitleMobileFontSize;
  line-height: 17px;
  color: $mainLightFontColor;
  text-align: center;

  &::first-letter {
    text-transform: uppercase;
  }

  @media (min-width: 720px) {
    font-size: $greetingUserTitleFontSize;
    line-height: 22px;
  }
}

.greeting-user-subtitle {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: $greetingUserTitleMobileFontSize;
  line-height: 17px;
  color: $mainDarkFontColor;
  text-align: center !important;

  &::first-letter {
    text-transform: uppercase;
  }

  @media (min-width: 720px) {
    font-size: $greetingUserSubtitleFontSize;
    line-height: 20px;
  }
}

.robot-img-container {
  display: flex;
  justify-content: center;
  margin-bottom: 65px;
  height: 600px;

  .robot-img {
    top: -20px;
    right: -60px;
    width: auto;
  }

  @media (min-width: 992px) {
    top: -58px;
    left: 40px;
    margin-bottom: 0;
  }
}
