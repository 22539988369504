@import '../../accets/scss/variables';

.sing-in-form {
  min-width: 285px;

  @media (min-width: 540px) {
    min-width: 316px;
  }

  @media (min-width: 768px) {
    max-width: 300px;
  }

  @media (min-width: 992px) {
    min-width: 395px;
  }
}

.modal-form {
  min-width: 395px;
}

.form-group-container {
  border-bottom: 1px solid transparent;
  margin-bottom: 3vh !important;
}

.form-group {
  margin-bottom: 30px !important;
}

.form-label {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: $loginFormFontSize;
  line-height: 15px;
  color: $loginFormLabelColor;

  &::first-letter {
    text-transform: uppercase;
  }
}

.form-control {
  font-family: $mainFontFamily;
  border: 1px solid $loginFormLabelColor !important;
  background-color: transparent !important;
  border-radius: 10px !important;
  display: flex !important;

  &:focus {
    box-shadow: 0 0 0 0.2rem $mainLightFontColor !important;
  }
}

.PhoneInputInput {
  border: none;
  background-color: inherit;

  &:focus {
    border: none;
    outline: none;
  }
}

.sign-in-control {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-bottom: 40px;

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.sign-in-link.btn.btn-link,
.password-reminder-link.btn.btn-link {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: $signInBtnFontSize;
  line-height: 15px;
  color: $loginFormLabelColor;
  padding: 10px 0 !important;
  min-width: 181px;

  &:hover,
  &:focus {
    text-decoration: none;
    box-shadow: none;
  }

  &::first-letter {
    text-transform: uppercase;
  }

  @media (min-width: 992px) {
    //margin-left: -20px;
  }
}

.password-reminder-link.btn.btn-link {
  color: $mainLightFontColor;
  margin-right: 0;
  margin-left: 0;
  //bottom: -60px;
  //left: 23%;
}

.sing-in-btn.btn.btn-primary {
  filter: brightness(100%) !important;
}

.sing-in-btn.btn,
.send-password-btn.btn {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: $signInBtnFontSize;
  line-height: 15px;
  color: $lightColor;
  padding: 10px 20px !important;
  background-color: $mainDarkFontColor !important;
  border: none;
  width: 213px;
  margin-bottom: 20px;

  &:focus {
    box-shadow: 0 0 0 0.2rem $mainLightFontColor !important;
  }

  &::first-letter {
    text-transform: uppercase;
  }

  @media (min-width: 992px) {
    margin-bottom: 0;
  }
}

.send-password-btn.btn {
  margin-bottom: 0;
}

.form-error {
  position: absolute;
  color: $mainLightFontColor;
  font-size: 10px;
  line-height: 13px;

  &::first-letter {
    text-transform: uppercase;
  }

  @media (min-width: 720px) {
    font-size: $btnChangeLangFontSize;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-color: $logoDarkColor;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 174, 255, 0.04) 50%,
    rgba(255, 255, 255, 0) 51%,
    rgba(0, 174, 255, 0.03) 100%
  );
}

.phone-placeholder {
  top: 0;
  left: 0;
}
