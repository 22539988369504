@import '../../accets/scss/variables';

.notification {
  width: 100%;
  padding: 20px 0;
  align-items: flex-start;
}

.notification-img-container {
  width: 56px;
  height: 56px;
  background-color: $btnChangeLangBgColor;
  border-radius: 10px;
  margin-right: 12px;

  &.watched {
    background-color: $notificationBtnBgColor;
  }
}

.notify-data {
  top: 10px;
  right: 30px;
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: $notificationDataFontSize;
  line-height: 10px;
  background-color: $btnChangeLangBgColor;
  padding: 3px 5px;
  border-radius: 5px;
}

.notify-title,
.notify-text {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: bold;
  font-size: $notificationTitleFontSize;
  line-height: 20px;
  color: $exampleFileBtnColor;

  &::first-letter {
    text-transform: uppercase;
  }
}

.notify-text {
  font-weight: 500;
  font-size: $notificationTextFontSize;
  margin-bottom: 10px;

  &:first-letter {
    text-transform: uppercase;
  }
}

.notify-text-container {
  margin-bottom: 10px;
}
