@import '../../accets/scss/variables';

.project-info-title,
.project-info-subtitle {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: bold;
  font-size: $signInBtnFontSize;
  line-height: 20px;
  color: $mainDarkFontColor;
  margin-bottom: 20px;

  &::first-letter {
    text-transform: uppercase;
  }

  @media (min-width: 992px) {
    font-size: $btnControlFontSize;
    line-height: 24px;
    margin-bottom: 40px;
  }
}

.project-info-subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: $navLinkTitleFontSize;
  line-height: 22px;
  letter-spacing: 0.04em;
  margin-bottom: 20px;
  text-align: center;

  @media (min-width: 992px) {
    text-align: start;
    line-height: 26px;
    margin-bottom: 40px;
    font-size: $loginFormFontSize;
  }
}

.employees-list,
.contact-persons-list {
  border-radius: 10px;
  padding: 20px 31px;
  background-color: $notificationBtnBgColor;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 40px;
    padding: 20px 51px;
  }

  @media (min-width: 992px) {
    padding: 20px 51px;
  }
}

.contact-persons-list {
  display: flex;
  flex-wrap: wrap;
}

.contact-persons-list-item {
  margin-bottom: 1rem;
  flex-basis: 100%;

  @media (min-width: 868px) {
    flex-basis: 50%;

    &:nth-child(odd) {
      border-right: 1px solid;
    }
  }
}

.employees-list-item {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: -10px;
    top: 0;
    width: 1px;
    height: 100%;
    border-right: 1px solid $contactsEmployeesSeparatorColor;
    display: none;
  }

  &:first-of-type {
    @media (min-width: 680px) {
      &::after {
        display: block;
      }
    }

    @media (min-width: 992px) {
      &::after {
        display: none;
      }
    }

    @media (min-width: 1120px) {
      &::after {
        display: block;
      }
    }
  }

  &:nth-child(2) {
    @media (min-width: 1420px) {
      &::after {
        display: block;
      }
    }
  }

  &:last-of-type {
    &::after {
      display: none;
    }
  }
}

.sales {
  display: grid;
  grid-gap: 20px;
  grid-template-areas:
    'sales-area sales-area sales-area'
    'product-area product-area product-area'
    'account-area account-area account-area';

  margin-bottom: 40px;

  @media (min-width: 680px) {
    grid-template-areas:
      'sales-area product-area'
      'account-area account-area';
  }

  @media (min-width: 992px) {
    grid-template-areas:
      'sales-area sales-area sales-area'
      'product-area product-area product-area'
      'account-area account-area account-area';
  }

  @media (min-width: 1120px) {
    grid-template-areas:
      'sales-area product-area'
      'account-area account-area';
  }

  @media (min-width: 1420px) {
    grid-template-areas: 'sales-area product-area account-area';
  }
}

.employee-container {
  padding-right: 20px;
  margin-bottom: 22px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: 672px) {
    margin-bottom: 0;
  }
}

.sales-area {
  grid-area: sales-area;
}

.product-area {
  grid-area: product-area;
}

.account-area {
  grid-area: account-area;
}

.contact-staff-container {
  width: 275px;
  padding-right: 0;
}

.employee-photo {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin-right: 10px;

  @media (min-width: 992px) {
    margin-right: 20px;
  }
}

.employee-name,
.employee-position {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: $btnChangeLangFontSize;
  line-height: 15px;
  color: $mainDarkFontColor;
  margin-bottom: 10px;
}

.employee-position {
  margin-bottom: 0;
  color: $privateRoomBtnTitleColor;

  &::first-letter {
    text-transform: uppercase;
  }
}

.employee-social-icon {
  &:first-of-type {
    margin-right: 20px;
  }
}

.sales-position {
  margin-right: 20px;
}

.carousel-button-group {
  .left-arrow,
  .right-arrow {
    cursor: pointer;
    position: absolute;
    border: none;
    top: -40px;
    right: -30px;
    background-color: transparent;
  }

  .right-arrow {
    top: -36px;
    left: -30px;
    transform: rotate(180deg);
  }
}

.required-links-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 20px;

  &:first-of-type {
    margin-right: -20px;
    margin-left: -20px;

    @media (min-width: 768px) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  &:last-of-type {
    margin-bottom: 30px;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
