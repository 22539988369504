@import '../../accets/scss/variables';

.project-schedule-container {
  min-height: 50vh;
}

.project-reporting {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: bold;
  font-size: $btnControlFontSize;
  line-height: 24px;
  color: $mainDarkFontColor;
  margin-bottom: 40px;
  left: 0;

  &::first-letter {
    text-transform: uppercase;
  }
}
//
//.project-schedule {
//  max-width: 100%;
//  display: flex;
//  overflow: auto;
//  padding-bottom: 5px;
//  margin-bottom: 30px;
//  height: 50vh;
//}
//
//.disable-hover,
//.disable-hover * {
//  pointer-events: none !important;
//}
//
//.gant-container > div {
//  pointer-events: none;
//}
//
//._3eULf {
//  justify-content: flex-start !important;
//}
//
//._CZjuD {
//  overflow: inherit !important;
//}
//
//._3eULf {
//  justify-content: center !important;
//}
//
//._1eT-t {
//  margin-left: 0 !important;
//  margin-top: 10px;
//}
//
//._CZjuD {
//  margin-right: -17px;
//}
//
//.empty-footer {
//  height: 100px;
//}
//
//._9w8d5 {
//  //display: none !important;
//}
//
//._19jgW {
//  overflow: inherit;
//}

.gant-container1 {
  width: 100%;
}

.gant-container2 {
  overflow: auto;
}
