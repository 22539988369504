.react-datepicker__header {
  text-align: center;
  background-color: #ffbb4f !important;
  border-bottom: none !important;
  border-top-left-radius: 0.3rem;
  padding: 15px 0;
  position: relative;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #ffbb4f !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #ffbb4f !important;
}
.react-datepicker__navigation-icon::before {
  border-color: #000;
}

.react-datepicker__navigation {
  top: 16px;
}

.react-datepicker__input-container {
  width: 250px;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 14px !important;
  line-height: 17px;
  font-family: Montserrat,sans-serif;
  font-style: normal;
  font-weight: normal;

  input {
    border: transparent;
    border-radius: 8px;
    padding: 8px;
  }

  &:focus {
    border: none;
    outline: 2px solid #ffbb4f !important;
    padding: 4px 10px;
  }
}

.react-datepicker-ignore-onclickoutside {
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;

  &:focus {
    border: none;
    outline: 2px solid #ffbb4f !important;
    padding: 10px 6px;
  }
}

.react-datepicker-ignore-onclickoutside {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 17px;
  color: #101010;
}

.red-border {
  border: 1px solid red;
}
