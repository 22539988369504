@import '../../accets/scss/variables';

.project-links-container {
  padding: 0 48px;

  @media (min-width: 411px) {
    padding: 0 90px;
  }

  @media (min-width: 576px) {
    padding: 0 150px;
  }

  @media (min-width: 768px) {
    padding: 0;
  }
}

.link-title-container {
  margin-bottom: 20px;
}

.title-link {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: $showProjectBtnFontSize;
  line-height: 26px;
  text-align: center;
  margin-bottom: 0;

  &:first-letter {
    text-transform: uppercase;
  }

  @media (min-width: 768px) {
    text-align: start;
  }
}

.figma-links-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 20px;

  &:first-of-type {
    margin-right: -20px;
    margin-left: -20px;

    @media (min-width: 768px) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  &:last-of-type {
    margin-bottom: 30px;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.figma-link-title {
  color: $mainLightFontColor;
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  max-height: 20px;
  max-width: 250px;
  overflow: hidden;
}

.figma-link-img {
  width: 12px;
  height: 12px;
  top: 10px;
  right: 10px;
}

.figma-link.btn.btn-link {
  background-color: $notificationBtnBgColor;
  border-radius: 10px;
  padding: 7px 32px 7px 10px;
  text-decoration: underline;
  color: $mainLightFontColor;

  &:hover {
    color: $mainLightFontColor;
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 0.2rem $mainLightFontColor;
  }
}
