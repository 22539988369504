@import '../../accets/scss/variables';

.authorizationError,
.registrationError,
.error {
  font-size: 14px !important;
  background-color: $rejectEditsBtnBorderColor;
  color: $lightColor;
  padding: 10px;
  border-radius: 10px;
  animation: appearError 6s ease, hideError 2s forwards 4s;

  &::first-letter {
    text-transform: uppercase;
  }
}

.email-error {
  bottom: -90px;
}

@keyframes appearError {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}

@keyframes hideError {
  from {
    opacity: 100;
  }

  to {
    opacity: 0;
  }
}
