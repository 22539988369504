@import '../../accets/scss/variables';

.bonus-modal {
  background-color: $lightColor;
  z-index: 301;
  padding: 30px 20px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-width: 400px;
}

.bonus-table-header {
  display: grid;
  grid-template-columns: 1fr 2fr;
  background-color: $mainDarkFontColor;

  th {
    font-family: $robotoFontFamily;
    font-style: normal;
    font-weight: bold;
    font-size: $newItemTitleFontSize;
    line-height: 16px;
    color: $lightColor;
  }
}

.bonus-table-row {
  display: grid;
  grid-template-columns: 1fr 2fr;

  & td {
    border: 1px solid $paymentTableBarBgColor;
  }
}
