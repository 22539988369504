.character {
  composes: body from '../ShopTab.module.scss';
  background: center / cover no-repeat url('../../../accets/img/character-bg.jpg');
}

.screenshotBtn {
  composes: btn from '../ShopTab.module.scss';

  &::after {
    background: center / contain no-repeat url('../../../accets/icons/screenshot-btn.png');
  }
}

.shareBtn {
  composes: btn from '../ShopTab.module.scss';

  &::after {
    background: center / contain no-repeat url('../../../accets/icons/share-btn.png');
  }
}
