.body {
  composes: body from '../ShopTab.module.scss';
  background: center / cover no-repeat url('../../../accets/img/personal-bg.jpg');
}

.cartBtn {
  composes: btn from '../ShopTab.module.scss';
  &::after {
    background: center / cover no-repeat url('../../../accets/icons/white-cart.png');
  }
}

.purchasesBtn {
  composes: btn from '../ShopTab.module.scss';
  &::after {
    background: red;
  }
}

.backBtn {
  composes: btn from '../ShopTab.module.scss';
  &::after {
    background: center / cover no-repeat url('../../../accets/icons/back-arrow.png');
  }
}
