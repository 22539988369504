.body {
  composes: container from '../../accets/scss/gamification/container.module.scss';
  padding: 40px 25px 53px;
  height: 77vh;
  display: flex;
  justify-content: space-between;
  overflow-y: hidden;
}

.btn {
  width: 60px;
  height: 60px;
  display: block;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #fff;
  backdrop-filter: blur(10px);
  border-radius: 4px;
  margin-right: 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }

  &::after {
    content: '';
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:first-child {
    margin-bottom: 10px;
  }
}
