@import '../../accets/scss/variables';

.comment-content {
  background-color: #f3f3f3;
  border-radius: 10px;
  padding: 10px 10px 20px 10px;
  margin-bottom: 40px;
  position: relative;
  display: flex;
  flex-direction: column;

  &:after {
    content: url('../../accets/img/cloud-tail.svg');
    position: absolute;
    bottom: -23px;
    left: 47px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: 992px) {
    margin-bottom: 10px;
    flex-direction: row;
    padding: 0;
  }
}

.self-message {
  background-color: #fff1dc;

  &:after {
    display: none;
  }

  &:before {
    content: url('../../accets/img/cloud-tail-self.svg');
    position: absolute;
    bottom: -24px;
    right: 47px;

    @media (min-width: 992px) {
      display: none;
    }
  }

  @media (min-width: 992px) {
    background-color: transparent;
    margin-left: 70px;
  }
}

.comment-user-img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 10px;
}

.comment-name,
.comment-text {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: $editsPageTitleFontSize;
  line-height: 15px;
  color: $mainDarkFontColor;

  @media (min-width: 992px) {
    color: $mainDarkFontColor;
    font-size: $commentNameFontSize;
  }
}

.comment-name {
  color: $lightColor;
  border-radius: 5px;
  background-color: $mainLightFontColor;
  width: fit-content;
  line-height: 20px;
  font-size: $paymentDataFontSize;
  margin-bottom: 20px;

  &:first-letter {
    text-transform: uppercase;
  }

  @media (min-width: 992px) {
    color: $mainDarkFontColor;
    background: transparent;
    font-size: $commentNameFontSize;
    margin-bottom: 10px;
  }
}

.self-name {
  background-color: $mainDarkFontColor;

  @media (min-width: 992px) {
    background: transparent;
  }
}

.comment-text {
  font-weight: 500;
  margin-bottom: 0;
}
