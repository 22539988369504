@import '../../accets/scss/gamification/variables.module.scss';

.img {
  width: 131px;
  height: 85px;
  border-radius: 4px;
}

.img_sm {
  width: 69px;
  height: 59px;
  border-radius: 4px;
  display: block;
  margin: 0 auto;
}

.title {
  composes: semi-bold-typography from '../../accets/scss/gamification/typography.module.scss';
  color: #fff;
  font-size: 14px;
  margin: 0 10px;
}

.costList {
  width: 59px;
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 28px;
}

.costItem {
  composes: bold-typography from '../../accets/scss/gamification/typography.module.scss';
  color: $primary-orange;
  font-size: 10px;
  line-height: 1;

  &::before {
    content: '';
    margin-right: 5px;
    margin-bottom: -2px;
    width: 10px;
    height: 10px;
    display: inline-block;
  }
}

.costList_sm {
  composes: costList;
  margin-bottom: 0;
}

.balance {
  display: block;

  &::before {
    background: center / contain no-repeat url('../../accets/icons/flex.svg');
  }
}

.kpi {
  display: inline-block;

  &::before {
    background: center / contain no-repeat url('../../accets/icons/kpi.svg');
  }
}

.level {
  display: inline-block;
  margin-right: 5px;

  &::before {
    background: center / contain no-repeat url('../../accets/icons/level.svg');
  }
}

.cartBtn {
  composes: btn from '../../accets/scss/gamification/btn.module.scss';
  border: none;
  width: 52px;
  height: 33px;
  position: relative;

  &::after {
    content: '';
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: center / cover no-repeat url('../../accets/icons/black-cart.png');
  }
}

.cartBtn_sm {
  composes: cartBtn;
  width: 30px;
  height: 25px;

  &::after {
    width: 12px;
    height: 13px;
  }
}

.removeBtn {
  composes: cartBtn;
  margin: 0 0 0 auto;
  max-width: 25px;
  height: 25px;
  &::after {
    content: 'X';
    width: unset;
    height: unset;
    margin: 0 auto;
    background: none;
  }
}

.wrapper {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.statusTxt {
  composes: regular-typography from '../../accets/scss/gamification/typography.module.scss';
  font-size: 14px;
}

.pending {
  color: #ccc;
}

.confirmed {
  color: #eeff56;
}

.sent {
  color: #56f9ff;
}

.complited {
  color: $primary-orange;
}

.statusWrapper {
  text-align: center;
}
