@import '../../accets/scss/variables';

.working-hours-modal {
  background-color: $lightColor;
  z-index: 301;
  padding: 30px 20px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 80%;
}

.working-hours-title,
.working-hours-subtitle {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: bold;
  font-size: $payrollTableFontSize;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: $mainDarkFontColor;
  margin-bottom: 20px;
}

.working-hours-subtitle {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  & .working-hours-subtitle-color {
    color: $mainLightFontColor;
  }
}

.working-hours-table {
  min-width: 700px;
}

.kpi-row {
  display: grid;
  grid-template-columns: 3fr 2fr 1fr 1fr 1fr 2fr 1fr;
}
