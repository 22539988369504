@import '../../accets/scss/variables';

.header-container {
  justify-content: space-between;

  @media (min-width: 992px) {
    justify-content: flex-end;
  }
}

.burger-btn.btn.btn-primary {
  background-color: transparent;
  border: none;

  &:active,
  &:hover,
  &:focus {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.notification-btn.btn.btn-primary,
.gaming-btn {
  margin-right: 14px;
  background-color: $notificationBtnBgColor;
  border: none;
  border-radius: 10px;
  width: 56px;
  height: 56px;

  &:nth-child(2) {
    margin-right: 24px;
  }

  &:focus,
  &:active {
    background-color: $notificationBtnBgColor !important;
    border: none;
    box-shadow: 0 0 0 0.2rem $mainLightFontColor !important;
  }

  .circle-notification {
    right: -4px;
    top: -6px;
    width: 15px;
    height: 15px;
    border-radius: 6px;
    background-color: $mainLightFontColor;
  }
}

.gaming-btn {
  color: black;
  width: auto;
}

.user-data-btn.btn.btn-primary {
  background-color: $lightColor;
  padding: 0;
  border: none;
  height: 56px;
  align-items: stretch;

  .btn-title-container {
    border: 1px solid $notificationBtnBgColor;
    border-radius: 0 10px 10px 0;
    padding: 9px 56px 9px 16px;
  }

  .user-photo-container {
    border: 1px solid $notificationBtnBgColor;
    border-radius: 10px;
    display: flex;
    align-items: flex-end;
    overflow: hidden;

    @media (min-width: 992px) {
      border-radius: 10px 0 0 10px;
      border-right: none;
    }
  }

  .user-photo {
    width: 56px;
    height: 54px;
  }

  .user-name,
  .btn-title {
    font-family: $mainFontFamily;
    font-style: normal;
    font-weight: 600;
    font-size: $btnChangeLangFontSize;
    line-height: 15px;
    color: $mainDarkFontColor;
    text-align: start;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .user-name {
    @media (min-width: 960px) {
      margin-bottom: 8px;
    }
  }

  .btn-title {
    color: $privateRoomBtnTitleColor;
    margin-bottom: 0;
  }

  .btn-icon {
    top: 23px;
    right: 27px;
    transform: rotate(90deg);
  }

  .gear-icon {
    bottom: -4px;
    right: 0;
  }

  &:focus,
  &:active {
    background-color: inherit !important;
    box-shadow: none !important;
  }
}
