@import '../../accets/scss/variables';

.project-reporting-btn {
  border: 1px solid $mainLightFontColor;
  border-radius: 10px;
  background-color: transparent;
  padding: 10px;
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: $projectReportingBtnFontSize;
  line-height: 20px;
  margin-right: 10px;
  color: $mainLightFontColor;
  margin-bottom: 20px;
  text-decoration: none;

  &:last-of-type {
    margin-right: 0;
  }

  &:active,
  &:focus,
  &.active-btn,
  &:hover {
    box-shadow: none !important;
    background-color: $mainLightFontColor !important;
    color: $lightColor !important;
    border: 1px solid $mainLightFontColor !important;
    text-decoration: none;
  }
}

.gant-container {
  margin: 0 auto;
}
