@import '../../accets/scss/gamification/variables.module.scss';

.body {
  composes: container from '../../accets/scss/gamification/container.module.scss';
  flex-grow: 1;
}

.userInfo {
  display: flex;
  align-items: center;
}

.userName {
  composes: bold-typography from '../../accets/scss/gamification/typography.module.scss';
  line-height: 22px;
}

.userJob {
  composes: regular-typography from '../../accets/scss/gamification/typography.module.scss';
  line-height: 22px;
}

.routeBtn {
  composes: btn from '../../accets/scss/gamification/btn.module.scss';
  width: 181px;
  line-height: 45px;
}
.routeBtn_black {
  composes: routeBtn;
  background-color: $light-black;
  color: #fff;
  margin-top: 10px;
}

.statsList {
  // list-style: none;
  // padding: 0;
}

.stat {
  composes: bold-typography from '../../accets/scss/gamification/typography.module.scss';

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: 3px;
  }
}

.statValueTxt {
  composes: bold-typography from '../../accets/scss/gamification/typography.module.scss';
  color: $primary-orange;
}

.balance {
  &::before {
    background: center / contain no-repeat url('../../accets/icons/flex.svg');
  }
}

.kpi {
  &::before {
    background: center / contain no-repeat url('../../accets/icons/kpi.svg');
  }
}

.level {
  &::before {
    background: center / contain no-repeat url('../../accets/icons/level.svg');
  }
}
