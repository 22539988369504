@import '../../accets/scss/variables';

.table-responsive {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: $lightColor;
  display: flex !important;
  flex-direction: column !important;
}

.table-row {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 1fr 2fr;
}

.dark-slot {
  background-color: $mainDarkFontColor;
  border-right: 1px solid $payrollHeaderBorderColor !important;
}

.dark-bg-slot {
  background-color: #697c86;
}

.light-color {
  color: $lightColor;
}

.slot {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem;
}

.border-slot {
  border: 1px solid #c4c4c4;
}

.color-slot {
  background-color: #409b6a;
  color: $lightColor;
}

.min-width {
  min-width: 96px;
}

.current-month {
  color: $mainLightFontColor !important;
}

.table-bar {
  background-color: $paymentTableBarBgColor;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.table-bar-menu.btn.btn-primary {
  background-color: transparent;
  border: none;
  height: 45px;

  &:active,
  &:focus {
    box-shadow: none !important;
    background-color: transparent !important;
  }
}

.table-bar-tab {
  border: 1px solid #c4c4c4;
  height: 45px;
  padding: 5px;
}

.active-tab {
  background-color: $lightColor;
  color: $mainLightFontColor;
}

.payroll-month-container {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  background-color: $lightColor;
  bottom: -16px;
  left: 0;
  max-height: 312px;
}

.active-month {
  color: $mainLightFontColor;
}

.cursor-pointer {
  cursor: pointer;
}
