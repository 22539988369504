@import '../../accets/scss/variables';

.right-section {
  justify-content: start;

  @media (min-width: 768px) {
    justify-content: center;
    background-color: $loginPageRightSectionBgColor;
  }
}

.language-switch-container {
  position: absolute;
  top: 54px;
  left: 76px;
}

.signin-container-control-group {
  background-color: $lightColor;
  height: 66px;
  margin-bottom: 36px;

  @media (min-width: 768px) {
    margin-bottom: 8vh;
  }
}
