@import '../../accets/scss/variables';

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 15px 0 15px;
  background-color: $lightColor;
  margin-bottom: 20px;
  z-index: 99;

  @media (min-width: 992px) {
    padding: 21px 110px 0 292px;
  }
}

.empty {
  height: 110px;
}

.navbar-container {
  padding: 0 110px;
}

.main {
  padding: 0 15px;

  @media (min-width: 992px) {
    padding: 0 110px;
  }
}
