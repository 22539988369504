@import '../../accets/scss/variables';

.edit-item {
  border-radius: 10px;
  background-color: $editItemBgColor;
  padding: 20px;
  padding-top: 58px;
  margin-bottom: 10px;
  position: relative;

  @media (min-width: 992px) {
    margin-bottom: 20px;
    padding-top: 20px;
    position: relative;
  }
}

.edit-title {
  top: 20px;
  left: 21px;
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: $editsPageTitleFontSize;
  line-height: 20px;
  color: $mainDarkFontColor;
}

.edit-item-text-container {
  @media (min-width: 992px) {
    padding-right: 60px;
  }
}

.edit-item-data,
.edit-item-time,
.edit-item-text {
  margin-right: 10px;
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: $btnChangeLangFontSize;
  line-height: 20px;
  color: #979797;

  @media (min-width: 992px) {
    margin-right: 20px;
    font-size: $loginFormFontSize;
    color: $editItemColor;
  }
}

.edit-item-time {
  margin-right: 38px;
}

.edit-item-text {
  margin-right: 0;
  margin-bottom: 46px;
  color: $editItemColor;
  font-size: $paymentDataFontSize;

  &::first-letter {
    text-transform: uppercase;
  }

  @media (min-width: 992px) {
    margin-bottom: 20px;
  }
}

.edit-item-btn.btn.btn-primary,
.delete-item-btn.btn.btn-primary {
  top: 15px;
  right: 54px;
  border: none;
  background-color: transparent;
  padding: 3px;

  &:focus,
  &:active {
    box-shadow: none !important;
    background-color: transparent !important;
  }
}

.delete-item-btn.btn.btn-primary {
  right: 20px;
}

.example-file.btn.btn-primary {
  margin-bottom: 17px;

  &:focus,
  &:active {
    box-shadow: none !important;
  }
}

.pdf-icon {
  margin-right: 10px;
  margin-left: -0.75rem;
}

.example-file-title {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: $projectLinksFontSize;
  line-height: 15px;
  text-decoration-line: underline;
  color: $exampleFileBtnColor;
}

.example-img {
  height: 120px;
  width: auto;
}

.comment-btn.btn.btn-primary {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: $editItemCommentTitleFontSize;
  line-height: 20px;
  text-decoration-line: underline;
  background-color: transparent;
  color: $mainLightFontColor;
  align-self: flex-start;
  padding: 0;
  border: none;
  margin-bottom: 20px;
  position: absolute;
  bottom: 0;
  right: 23px;

  &:focus,
  &:active {
    box-shadow: none !important;
    color: $mainLightFontColor !important;
    background-color: transparent !important;
  }

  @media (min-width: 992px) {
    position: relative;
    right: 0;
  }
}

.comments-container {
  position: fixed;
  top: 101px;
  left: 0;
  right: 0;
  background: white;
  bottom: 0;
  z-index: 50;
  padding: 0 10px;

  @media (min-width: 500px) {
    padding: 0 40px;
  }

  @media (min-width: 600px) {
    padding: 0 80px;
  }

  @media (min-width: 700px) {
    padding: 0 120px;
  }

  @media (min-width: 992px) {
    position: relative;
    top: 0;
    background: transparent;
    padding: 0;
  }
}

.comment-field-title {
  max-width: 165px;
  text-align: center;
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: $mainDarkFontColor;
  margin-bottom: 40px;
}

.comments-form-container {
  position: absolute;
  bottom: 0;
  left: 11px;
  right: 10px;
  border-radius: 10px;
  background-color: $editFormBgColor;
  padding: 15px;
  margin: 0 -10px 20px -10px;
}

.comment-form-container-mobile {
  position: fixed;
  bottom: 50px;
  left: 0;
  border-radius: 10px;
  background-color: $editFormBgColor;
  padding: 15px;
  margin: 0 -10px 20px -10px;
  width: 100%;
}

.btn-back.btn.btn-primary {
  top: 0;
  left: 0;
}
