@import '../../accets/scss/gamification/variables.module.scss';

.body {
  width: 419px;
}

.body_cart {
  composes: body;
  height: 82%;
}

.controls {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
}

.switchBtn {
  composes: semi-bold-typography from '../../accets/scss/gamification/typography.module.scss';
  flex-basis: 30%;
  text-align: center;
  line-height: 38px;
  background-color: #212529;
  border: 1px solid #fff;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    opacity: 0.8;
  }
}

.switchBtn_xl {
  composes: switchBtn;
  flex-grow: 0.95;
}

.active {
  composes: switchBtn;
  transition: all 0.3s;
  background-color: $secondary-orange;
  color: #000;
}

.listWrapper {
  height: calc(100% - 38px);
  padding: 20px 10px 20px 17px;
  margin: 0;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #fff;
  backdrop-filter: blur(10px);
  border-radius: 4px;
  overflow-y: hidden;
}

.list {
  list-style: none;
  height: 100%;
  padding: 0 13px 0 0;
  margin: 0;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 74px;
    background: #212529;
    background-color: red;
  }

  &::-webkit-scrollbar-thumb {
    background: #fff;
    width: 6px;
    border-radius: 15px;
  }
}

.list_sm {
  composes: list;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px;
}

.item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: rgba(33, 37, 41, 0.4);
  border: 1px solid transparent;
  backdrop-filter: blur(20px);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background: rgba(33, 37, 41, 0.6);
  }

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

.item_sm {
  composes: item;
  flex-basis: 106px;
  flex-wrap: wrap;
  align-content: flex-start;
  height: fit-content;
  display: block;
  padding: 5px;

  &:not(:last-of-type) {
    margin-bottom: 0;
  }
}

.chosen {
  composes: item;
  background: rgba(247, 172, 22, 0.2);
  border: 1px solid #f7ac16;

  &:hover {
    background: rgba(247, 172, 22, 0.3);
  }
}

.chosen_sm {
  composes: item_sm;
  background: rgba(247, 172, 22, 0.2);
  border: 1px solid #f7ac16;

  &:hover {
    background: rgba(247, 172, 22, 0.3);
  }
}

.emptyProductsList {
  composes: semi-bold-typography from '../../accets/scss/gamification/typography.module.scss';
  flex-basis: 419px;
  text-align: center;
  margin: 0;
  color: #fff;
}

.body_total {
  padding: 10px;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #fff;
  backdrop-filter: blur(10px);
  border-radius: 4px;
}

.totals_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.total {
  composes: semi-bold-typography from '../../accets/scss/gamification/typography.module.scss';

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 5px;
  }
}

.balance {
  &::before {
    background: center / contain no-repeat url('../../accets/icons/flex.svg');
  }
}

.kpi {
  &::before {
    background: center / contain no-repeat url('../../accets/icons/kpi.svg');
  }
}

.level {
  &::before {
    background: center / contain no-repeat url('../../accets/icons/level.svg');
  }
}

.enough {
  composes: bold-typography from '../../accets/scss/gamification/typography.module.scss';
  color: $primary-orange;
}

.notEnough {
  composes: enough;
  color: red;
}

.confirmBtn {
  composes: btn from '../../accets/scss/gamification/btn.module.scss';
  border: none;
  width: 70%;
  margin: 0 auto;
  height: 45px;

  &:disabled {
    background-color: rgba(238, 238, 238, 0.5);
    &:hover {
      opacity: unset;
    }
  }
}
