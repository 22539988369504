@import 'src/accets/scss/variables';

.title-page-container {
  height: 42px;
  margin-bottom: 40px;
}

.finance-title {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: bold;
  font-size: $financeTitleFontSize;
  line-height: 22px;
  color: $mainDarkFontColor;
  margin-bottom: 0;

  &::first-letter {
    text-transform: uppercase;
  }
}

.payment-table {
  width: 100%;

  @media (min-width: 768px) {
    overflow: auto;
    padding-right: 11px;
  }

  @media (min-width: 992px) {
    overflow: inherit;
    padding-right: 0;
  }

  @media (min-width: 1200px) {
    overflow: auto;
    padding-right: 11px;
  }
}

.payment-container {
  @media (min-width: 768px) {
    height: 330px;
  }
}

.pay-table {
  display: none;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 3fr 2fr;
    margin-bottom: 21px;
  }
  @media (min-width: 992px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: grid;
  }
}

.table-title {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: $downloadBillBtnFontSize;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: $mainDarkFontColor;

  &::first-letter {
    text-transform: uppercase;
  }
}
