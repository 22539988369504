@import '../../accets/scss/gamification/variables.module.scss';

.card {
  flex-basis: 580px;
  background: rgba(33, 37, 41, 0.6);
  border: 1px solid #ffffff;
  backdrop-filter: blur(11px);
  border-radius: 4px;
  padding: 20px 10px 0 20px;
  overflow-y: hidden;
  margin-right: 20px;
}

.card_noInfo {
  composes: card;
  padding: 20px;
}

.about {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-basis: 580px;
}

.productImg {
  width: 205px;
  height: 137px;
  border-radius: 4px;
}

.productImg_big {
  order: 2;
  display: block;
  margin: 22px auto 0;
  height: 42vh;
}

.infoWrapper {
  flex-basis: 179px;
}

.infoWrapper_widthXl {
  flex-basis: 70%;
}

.title {
  composes: semi-bold-typography from '../../accets/scss/gamification/typography.module.scss';
  color: #fff;
  margin: 0 0 38px;
}

.titleShoppingCart {
  composes: title;
  max-width: 179px;
}

.title_marginSm {
  composes: title;
  margin: 0 0 20px;
}

.subtitle {
  color: $primary-orange;
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 0;
}

.addBtn {
  composes: btn from '../../accets/scss/gamification/btn.module.scss';
  border: none;
  width: 124px;
  line-height: 45px;
}

.costList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: flex-start;
  max-width: 114px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.costItem {
  composes: bold-typography from '../../accets/scss/gamification/typography.module.scss';
  color: $primary-orange;

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-color: $primary-orange;
    display: inline-block;
  }
}

.balance {
  &::before {
    background: center / contain no-repeat url('../../accets/icons/flex.svg');
  }
}

.kpi {
  &::before {
    background: center / contain no-repeat url('../../accets/icons/kpi.svg');
  }
}

.level {
  margin-right: 10px;

  &::before {
    width: 25px;
    background: center / contain no-repeat url('../../accets/icons/level.svg');
  }
}

.details {
  height: 42vh;
  border: 1px solid transparent;
  padding: 1px;
  box-sizing: border-box;
  margin-top: 33px;
  overflow-y: auto;
  padding-right: 30px;
  padding-bottom: 60px;

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 74px;
    background: rgba(255, 255, 255, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 15px;
  }
}

.detailsTxt {
  composes: semi-bold-typography from '../../accets/scss/gamification/typography.module.scss';
  color: #fff;
  font-size: 14px;
  margin: 0;
}

.additional {
  margin: 0;
  padding-left: 20px;
  list-style: disc;
}

.placeLink {
  composes: detailsTxt;
  display: block;
  text-decoration: none;
}

.noItem {
  height: 100%;
  position: relative;
}

.noItemTxt {
  composes: semi-bold-typography from '../../accets/scss/gamification/typography.module.scss';
  color: #fff;
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.statusTxt {
  composes: regular-typography from '../../accets/scss/gamification/typography.module.scss';
  font-size: 14px;
}

.pending {
  color: #ccc;
}

.confirmed {
  color: #eeff56;
}

.sent {
  color: #56f9ff;
}

.complited {
  color: $primary-orange;
}
