@import '../../accets/scss/variables';

.logout-modal {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 200;
  right: 0;
  height: 100%;
  max-height: 500px;
  overflow: auto;
  background-color: $lightColor;
  box-shadow: 0 0 5px $btnChangeLangColor;
  padding: 10px 20px 20px;

  @media (min-width: 768px) {
    padding: 10px 80px 20px;
  }

  @media (min-width: 992px) {
    border-radius: 10px;
    top: 82px;
    right: 97px;
    width: 275px;
    height: auto;
    padding: 10px 20px 20px;
  }
}

.profile-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 199;
}

.btn-close.position-absolute.btn.btn-primary {
  top: 45px;
  left: 0;
  background-color: transparent;
  border: none;
  padding: 5px;
  line-height: 10px;

  &:active,
  &:focus,
  &:hover {
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
  }

  @media (min-width: 992px) {
    right: -6px;
    left: auto;
    top: 5px;
  }
}

.btn-close-img {
  width: 10px;
  height: 10px;
}



.logout-modal-photo {
  margin-bottom: 5px;
  border: 1px solid $notificationBtnBgColor;
  border-radius: 10px;
  height: 100px;
  width: 100px;

  @media (min-width: 992px) {
    height: 56px;
    width: 56px;
  }
}

.img {
  width: 100%;
  height: auto;
}

.logout-modal-default-user-photo {
  margin-bottom: 10px;
  border-radius: 10px;
  height: 100px;
  width: 100px;
  padding: 5px;
  border: 1px solid $notificationBtnBgColor;

  @media (min-width: 992px) {
    height: 56px;
    width: 56px;
  }
}

.profile-user-name {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: bold;
  font-size: $profileUserNameFontSize;
  line-height: 20px;
  text-align: center;
  color: $mainDarkFontColor;
  margin-bottom: 40px;
}

.profile-photo-container {
  width: 100px;
}

.btn-change-photo-icon {
  bottom: 0;
  right: 0;
}

.btn-change-photo {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: $btnChangeLangFontSize;
  line-height: 15px;
  color: $mainDarkFontColor;
  text-decoration: underline;
  margin-bottom: 20px;

  &::first-letter {
    text-transform: uppercase;
  }
}

.btn-change-photo {
  cursor: pointer;
}

.logout-input,
.logout-input-error {
  margin-bottom: 25px;
  border: 1px solid $btnChangeLangColor;
  border-radius: 10px;
  padding: 9px 30px 9px 10px;
  width: 100%;

  &:focus,
  &:focus-visible {
    border-color: $mainLightFontColor;
    outline: none;
  }

  &::placeholder {
    font-family: $mainFontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: $btnChangeLangFontSize;
    line-height: 15px;
    text-transform: capitalize;
  }
}

.logout-input-error {
  border: 1px solid $rejectEditsBtnBorderColor;

  &:focus {
    border-color: $rejectEditsBtnBorderColor;
  }
}

.profile-btn-container {
  margin-top: 35px;

  @media (min-width: 992px) {
    flex-direction: column;
    margin-top: 15px;
  }
}

.form-logout-btn-save.btn.btn-primary,
.form-logout-btn-logout.btn.btn-primary {
  background: $mainLightFontColor;
  border-radius: 10px;
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border: none;
  width: 117px;
  height: 40px;
  color: $lightColor;
  margin-right: 20px;

  &:active,
  &:focus,
  &:hover {
    background-color: $mainLightFontColor !important;
    box-shadow: none !important;
    color: $lightColor !important;
  }

  &::first-letter {
    text-transform: uppercase;
  }

  @media (min-width: 992px) {
    background-color: transparent;
    font-size: $btnChangeLangFontSize;
    line-height: 15px;
    color: $mainLightFontColor;
    margin-right: 0;

    &:active,
    &:focus,
    &:hover {
      background-color: transparent !important;
      box-shadow: none !important;
      color: $mainLightFontColor !important;
    }
  }
}

.form-logout-btn-logout.btn.btn-primary {
  background: $mainDarkFontColor !important;
  margin-right: 0;

  &:active,
  &:focus,
  &:hover {
    background-color: $mainDarkFontColor !important;
    box-shadow: none !important;
    color: $lightColor !important;
  }

  @media (min-width: 992px) {
    background-color: transparent !important;
    box-shadow: none !important;
    color: $mainDarkFontColor !important;

    &:active,
    &:focus,
    &:hover {
      background-color: transparent !important;
      box-shadow: none !important;
      color: $mainDarkFontColor !important;
    }
  }
}
