@import '../../accets/scss/variables.scss';

.welcome-text {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: $loginFormFontSize;
  line-height: 20px;
  color: $mainDarkFontColor;

  &:first-of-type {
    margin-bottom: 20px;
  }

  &:last-of-type {
    margin-top: 0;
    margin-bottom: 40px;
    max-width: 82%;
    text-align: center;

    @media (min-width: 720px) {
      margin-bottom: 60px;
    }
  }

  &::first-letter {
    text-transform: uppercase;
  }

  @media (min-width: 720px) {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: $welcomeTextFontSize;
  }
}

.welcome-link.btn.btn-link {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: $signInBtnFontSize;
  line-height: 15px;
  background-color: $mainDarkFontColor;
  color: $lightColor;
  padding: 12px 39px;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem $mainLightFontColor;
  }
}
