@import '../../accets/scss/variables';

.confirmation-modal-container {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
  padding-top: 66px;
  z-index: 250;
}

.confirmation-modal {
  min-width: 443px;
  min-height: 150px;
  background-color: $lightColor;
  //padding: 10px 20px;
  padding: 40px 40px 0 40px;
  box-shadow: $notificationBoxShadow;
  border-radius: 10px;
  z-index: 200;
}

.confirmation-modal-text {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: $editsPageTitleFontSize;
  line-height: 20px;
  color: $mainDarkFontColor;
  margin-bottom: 40px;

  &:first-letter {
    text-transform: uppercase;
  }
}

.remembered-password-btn.btn.btn-link {
  bottom: 30px;
}
