@import '../../accets/scss/variables';

.sidebar {
  top: 95px;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 40px;
  z-index: -1;

  @media (min-width: 992px) {
    position: relative;
    background-color: $sideBarBgColor;
    width: 116px;
    top: 0;
    left: 0;
    bottom: 0;
    transition: width 0.2s;
    z-index: 100;
  }
}

.fixed-bg {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100px;
  background-color: $sideBarBgColor;
  z-index: 100;
  width: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: 992px) {
    width: 292px;
  }
}

.fixed-bg-close {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 116px;
  height: 100px;
}

.sidebar-close-btn.btn.btn-primary,
.sidebar-burger-btn.btn.btn-primary {
  background-color: transparent;
  border: none;
  position: absolute;
  margin-left: 30px;
  right: 30px;
  padding: 0;

  &:hover,
  &:focus {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}
.sidebar-burger-btn.btn.btn-primary {
  position: relative;
  margin-left: 0;
  right: 0;
}

.sidebar.sidebar-open {
  width: 100%;
  background-color: $lightColor;
  top: 100px;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: 992px) {
    top: 0;
    width: 292px;
    background-color: $sideBarBgColor;
    min-height: 100%;
    padding: 88px 10px 60px;
  }
}

.sidebar-lg-switch {
  margin-top: 20px;
  margin-bottom: 40px;
}

.sidebar-title {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 800;
  font-size: $sideBarTitleMobileFontSize;
  line-height: 29px;
  letter-spacing: -0.04em;
  color: $mainDarkFontColor;
  margin-bottom: 20px;
  animation: showContent 0.2s;

  &::first-letter {
    text-transform: uppercase;
  }

  @media (min-width: 992px) {
    font-size: $sideBarTitleFontSize;
    line-height: 39px;
  }

  @media (min-width: 992px) {
    margin-top: 100px;
  }
}

.sidebar-logo-container {
  margin-left: 30px;
}

.sidebar-projects {
  border-top: 1px solid $sideBarBorderColor;
  border-bottom: 1px solid $sideBarBorderColor;
  padding: 20px 0;
  animation: showContent 0.2s;
  margin: 0 50px 20px 50px;

  @media (min-width: 768px) {
    margin: 0 0 40px 0;
    padding: 40px 0;
  }
}

.project-btn-container {
  padding-right: 6px;

  @media (min-height: 568px) {
    height: 175px;
  }

  @media (min-height: 768px) {
    height: 175px;
  }

  @media (min-height: 812px) {
    height: 269px;
  }

  @media (min-height: 900px) {
    height: 351px;
  }

  @media (min-height: 1024px) {
    height: 441px;
  }

  @media (min-width: 1200px) {
    padding-right: 10px;
  }
}

.show-project-btn.btn.btn-primary {
  font-family: $mainFontFamily;
  background-color: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: $showProjectBtnFontSize;
  line-height: 20px;
  color: $loginFormLabelColor;
  border: none;
  margin-bottom: 20px;

  &::first-letter {
    text-transform: uppercase;
  }

  &:focus,
  &:active {
    box-shadow: none !important;
    background-color: transparent !important;
    border: none !important;
    color: $loginFormLabelColor !important;
  }

  .sidebar-arrow-btn {
    position: absolute;
    top: 12px;
    right: -14px;
    transform: rotate(90deg);

    &.btn-active {
      transform: rotate(0deg);
    }
  }
}

a.sidebar-project-btn {
  background: $lightColor;
  border-radius: 20px;
  padding: 27px 17px 27px 20px;
  margin-bottom: 10px;
  color: $mainDarkFontColor;
  width: 192px;
  height: 70px;
  border: 1px solid #c2c2c2;

  .project-btn-icon {
    margin-right: 10px;
  }

  .project-btn-title {
    width: 100%;
    text-align: start;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  .project-btn-arrow-icon {
    position: relative;
    right: 17px;
  }

  &.active-navbar-btn,
  &:focus,
  &:active {
    box-shadow: none !important;
    color: $mainDarkFontColor !important;
    background: $btnChangeLangBgColor !important;
    border: 1px solid $mainLightFontColor !important;

    @media (min-width: 992px) {
      background: $lightColor !important;
    }
  }

  &:hover {
    text-decoration: none;
    color: $mainLightFontColor;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: 992px) {
    border: none !important;
    margin-bottom: 11px;
    width: 100%;
  }

  @media (min-width: 1500px) {
    width: 100%;
  }
}

.sidebar-contacts {
  animation: showContent 0.2s;
  margin-bottom: 20px;
}

.sidebar-phone,
.sidebar-web-site-link.btn.btn-link {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: $btnChangeLangFontSize;
  line-height: 15px;
  color: $btnChangeLangColor;

  &:nth-child(2) {
    margin-bottom: 10px;
  }
}

.sidebar-web-site-link.btn.btn-link {
  margin-bottom: 21px;

  &:hover {
    color: $mainLightFontColor;
  }

  &:active,
  &:focus {
    box-shadow: none;
  }
}

.sidebar-icon-color {
  display: none;
}

.social-link.btn.btn-link {
  background-color: transparent;
  overflow: hidden;
  width: 30px;
  height: 30px;
  padding: 0;
  margin-right: 5px;

  &:last-of-type {
    margin-right: 0;
  }

  &:active,
  &:focus {
    box-shadow: none;
  }

  &:hover .sidebar-icon-color {
    display: block;
    position: absolute;
    left: 3px;
    bottom: 4px;
    z-index: 10;
  }
}

.sidebar-icon {
  position: absolute;
  left: 3px;
  bottom: 4px;
}

@keyframes showContent {
  0%,
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sidebar-nav-container {
  padding-top: 30px;
  border-top: 1px solid $sideBarBorderColor;
  border-bottom: 1px solid $sideBarBorderColor;
  height: 560px;
  margin-bottom: 40px;
}
