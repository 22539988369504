@import '../../accets/scss/variables';

.news-item-container {
  display: flex;
  align-items: center;
  padding: 20px;
}

.news-item-content {
  border-radius: 10px;
  background-color: $newsItemBgColor;
  padding: 20px;
}

.news-item-data {
  background-color: $btnChangeLangBgColor;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 3px 13px;
}

.news-item-title {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: $newItemTitleFontSize;
  line-height: 160%;
  letter-spacing: 0.04em;
  color: $mainDarkFontColor;
}

.news-item-img {
  max-height: 230px;
  max-width: 100%;
}

.news-item-text {
  max-height: 238px;
  overflow: hidden;

  @media (min-width: 1000px) {
    max-height: 142px;
  }
}

.see-more-btn.btn.btn-primary {
  background-color: $mainLightFontColor;
  border-radius: 10px;
  width: 212px;
  height: 40px;
  color: $lightColor;
  border: none;

  &:focus,
  &:active {
    box-shadow: none !important;
    color: $lightColor !important;
    background-color: $mainLightFontColor !important;
  }
}
