.txt {
  composes: txt from '../Modal.module.scss';
}

.socialsWrapper {
  margin-right: 40px;
}

.socials {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 108px;
}

.link {
  &::before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
  }
}

.tg {
  &::before {
    background: center / contain no-repeat url('../../../accets/icons/tg-gamification.png');
  }
}

.fb {
  &::before {
    background: center / contain no-repeat url('../../../accets/icons/fb-gamification.png');
  }
}

.download {
  &::before {
    width: 24px;
    height: 18px;
    background: center / contain no-repeat url('../../../accets/icons/download-gamification.png');
  }
}
