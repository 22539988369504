@import '../../accets/scss/variables';

.link-input-container {
  border-radius: 10px;
  background-color: $notificationBtnBgColor;
  padding: 10px;
}

.input-value {
  max-width: 114px;
  overflow: hidden;
  left: 20px;
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: $projectLinksFontSize;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.link-input {
  padding-right: 24px;
  opacity: 0;
  width: 158px;
  height: 12px;
}

.link-input-btn.btn.btn-primary {
  background-color: transparent;
  border: none;
  right: 0;

  &:focus,
  &:active {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.input-alert {
  color: $logoLightColor;
  font-weight: bold;
  top: -20px;
  left: -45px;
}
