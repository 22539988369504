@import '../../accets/scss/variables';

.modal-notification-container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  padding-top: 66px;
  z-index: 250;
}

.modal-notification {
  width: 443px;
  height: 640px;
  top: 66px;
  left: 40%;
  background-color: $lightColor;
  padding: 40px 20px;
  box-shadow: $notificationBoxShadow;
  border-radius: 10px;
  z-index: 200;
}

.notification-content {
  width: fit-content;
}

.notifications-title {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: $editsPageTitleFontSize;
  line-height: 20px;
  color: $mainDarkFontColor;
  margin-bottom: 20px;

  &:first-letter {
    text-transform: uppercase;
  }
}

.close-btn.btn.btn-primary {
  background-color: transparent;
  border: none;
  top: -30px;
  right: 0;

  &:focus,
  &:active {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.notification-see-more-btn.btn.btn-primary {
  background-color: transparent;
  color: $loginFormLabelColor;
  border: none;

  &:first-letter {
    text-transform: uppercase;
  }

  &:focus {
    box-shadow: none !important;
    background-color: transparent !important;
    color: $loginFormLabelColor !important;
  }
}
