@import './variables.module.scss';

.btn {
  composes: semi-bold-typography from './typography.module.scss';
  display: block;
  background-color: $primary-orange;
  color: #000000;
  text-decoration: none;
  text-align: center;
  border-radius: 4px;
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    color: #000000;
    text-decoration: none;
    opacity: 0.8;
  }
}
